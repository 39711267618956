import { v4 as uuidv4 } from "uuid";

const FooterSection = ({ title, subTitle }) => {
  // const handleOpenCrisp = () => {
  //   try {
  //     $crisp.push(["do", "chat:open"]);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const openEmailClient = () => {
    const emailAddress = "support@omegatheme.com";
    const subject = "";
    const body = "";

    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`;

    window.location.href = mailtoUrl;
  };

  return (
    <div className="col-md-3 col-lg-3 col-sm-6 col-xs-12 footer-section">
      <h2 className="mb-4 mt-4 footer-title">{title}</h2>
      {subTitle.map((item) => (
        <p key={uuidv4()}>
          {item.text === "Contact Us" ? (
            <button className="btn-footer" onClick={openEmailClient}>
              {item.text}
            </button>
          ) : (
            <a
              href={item.href}
              className="text-reset sub-text"
              style={{ cursor: "pointer" }}
              target={item.target}
            >
              {item.text}
            </a>
          )}
        </p>
      ))}
    </div>
  );
};

export default FooterSection;
