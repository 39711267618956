import React, { useState } from "react";
import FormData from "form-data";
import axios from "axios";

import { Modal } from "react-bootstrap";
import { useStore, actions } from "../store";

function ModalRe() {
  const [state, dispatch] = useStore();
  const [shopName, setShopName] = useState("");
  const [err, setErr] = useState("");

  const handleChangeShopName = (value) => {
    setShopName(value);
  };

  let form = new FormData();

  form.append("shop_url", shopName);

  const url = "https://apps.synctrack.io/add-paypal-tracking/services.php";

  const handleSubmit = () => {
    axios({
      method: "post",
      crossDomain: true,
      contentType: "application/x-www-form-urlencoded; charset=UTF-8",
      url: url,
      data: form,
    }).then((res) => {
      console.log(res);
      const urlRedirect = res.data.url_redirect;
      window.open(urlRedirect, "_blank");
    });
  };

  const checkShopName = () => {
    if (shopName.length <= 14 || shopName.slice(-14) != ".myshopify.com") {
      console.log("err");
      setErr("Shop name you entered is invalid!");
    } else {
      handleSubmit();
    }
  };

  const pressKeyEnter = (e) => {
    if (e.key === "Enter") {
      checkShopName();
    }
  };

  return (
    <>
      <Modal
        show={state.modalOpen}
        onHide={() => dispatch(actions.setModal(false))}
      >
        <Modal.Header closeButton>
          <h3 className="st-h5">Install "Synctrack - Add Tracking Info"</h3>
        </Modal.Header>
        <Modal.Body>
          <div className="modal-submit-body">
            <p className="body-sm text-center">Enter your's Shopify URL:</p>
            <input
              type="text"
              value={shopName}
              onChange={(e) => handleChangeShopName(e.target.value)}
              onKeyPress={(e) => pressKeyEnter(e)}
              className="form-control text-center body-sm"
              placeholder="shopname.myshopify.com"
            />
            <small className="">
              Example: add-paypal-tracking.myshopify.com
            </small>
            <small style={{ color: "#dc3545" }}>{err}</small>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn-main" onClick={checkShopName}>
            Submit
          </button>
          <button
            type="button"
            className="btn-disable body-sm"
            onClick={() => dispatch(actions.setModal(false))}
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalRe;
