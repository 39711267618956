import FooterSection from "./Footer/FooterSection";
import { v4 as uuidv4 } from "uuid";
const Footer = ({ data }) => {
  const dataMapFooter = data;
  return (
    <>
      <hr id="footer-line" className="container-fluid" />
      <div className="footer-wrap">
        <footer className="text-center text-lg-start text-muted footer">
          <section className="container">
            <div className="text-center text-md-start">
              <div className="row mt-3">
                {Array.isArray(dataMapFooter.data) &&
                  dataMapFooter.data.map((item) => (
                    <FooterSection
                      title={item.title}
                      subTitle={item.subTitle}
                      key={uuidv4()}
                    />
                  ))}
              </div>
            </div>
          </section>

          <div className="text-center p-4">
            <img
              src="/images/homepage/newImage/Blockify_logo.png"
              className="mb-4"
              style={{ height: "40px" }}
              alt="logo"
            />
            <p>© 2023 Synctrack, Inc. All rights reserved.</p>
            {/* <div className="mb-4">
              {dataMapFooter.socialMedia.icon.map((item) => (
                <a
                  href={item.href}
                  target="_blank"
                  style={{ marginRight: "8px" }}
                >
                  <img src={`images/${item.logo}`} alt="social media logo" />
                </a>
              ))}
            </div> */}
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
