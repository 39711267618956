import Footer from './Footer';
import Script from 'next/script';
import ModalRe from './ModalRe';

const Layout = ({ children, data }) => {
  const { footer } = data;
  return (
    <>
      <Script type="text/javascript" src="/static/bootstrap.min.js" />
      <Script
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.gaKey}`}
      />
      <Script
        type="text/javascript"
        src="https://blockify.synctrack.io/api/file/blockify-embed?instanceId=7ee4b267-b97f-43c8-bb82-a4bd46c6fa01"
        async
      ></Script>
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${process.env.gaKey}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      />
      <ModalRe />
      {children}
      <Footer data={footer} />
    </>
  );
};

export default Layout;
